<template>
  <div id="dashboard-press">
    <EmailVerificationRequired v-if="!emailVerified"></EmailVerificationRequired>

    <!-- <div v-else-if="emailVerified" class="wrapper-dashboard-info duke-style">
      <div class="jcard-dashboard-info">
        <div>
          <p class="p4">Exclusive</p>
          <h1>Duke Club</h1>
        </div>
        <hr class="border" />

        <div>
          <div>
            <p class="mt-3 wht p ">
              Hello{{ userName }},
              <br />
              <strong>Exclusive DUKE content</strong> will be available soon.<br /><br />
            </p>
          </div>
          <br />

          <button type="button" class="jbtn jbtn-small mt-4" @click="logout()">
            <i class="fal fa-sign-in-alt"></i> Signout
          </button>
        </div>
      </div>
    </div> -->

    <!-- Press verification => deactivated so far (by v-if) -->
    <div v-else class="wrapper">
      <!--------------------- loading --------------------->
      <Loading class="section-dark section-loading"></Loading>
      <!--------------------- section unverified --------------------->
      <section v-if="
        !isLoading && !isVerifiedPress && userDoc.status != 13
      " key="verification" class="section-duke section-dark jpadding jpadding-25 duke-style">

        <div class="jtitle jtitle-80 jtitle-t40 text-center">
          <h3><small>DUKE</small><br />Press</h3>
        </div>


        <div class="jcard-duke jcard-duke-large">
          <div class="text-center mb-3">
            <h3 class="h3-sm"><small>VERIFICATION</small><br />IN PROGRESS</h3>
          </div>
          <div>
            <i class="fad fa-lock fa-3x wht"></i>
          </div>
          <div class="pl-5 pr-5 mt-3 text-center">
            <p>
              <small>Thank you for creating your
                <strong>DUKE Club Press</strong> account.<br />
                In order to get access to exclusive DUKE content your account
                needs to be <strong>verified by our team</strong>. Please come
                back here later to check out the status of your
                verification.</small>
            </p>

            <div class="grid-3 mt-5 mb-2">
            <div class="jcard-border">
              <p class="p1-platform">
                <small><i class="fal fa-user"></i> ACCOUNT</small><br />{{ userDoc.email }}
              </p>
            </div>


            <div class="jcard-border">
              <p class="p1-platform">
                <small><i class="fal fa-cog"></i> ID</small><br />{{ userId.substring(0, 8) }}
              </p>
            </div>
            <div class="jcard-border">
              <p class="p1-platform">
                <small><i class="fal fa-calendar-alt"></i> REQUESTED ON</small><br />{{ $moment($C.getDateFromTimestamp(userDoc.createdOn)).format(
                      "DD-MM-YYYY") }}
              </p>
            </div>


          </div>

            
           
          </div>
        </div>
      </section>

      <!--------------------- section declined --------------------->
      <section v-if="
        !isLoading && !isVerifiedPress && userDoc.status == 13
      " key="verification" class="section-duke section-dark jpadding jpadding-25">
        <div class="jtitle jtitle-80 text-center">
          <h3>PRESS CLUB</h3>
        </div>

        <div class="jcard-duke jcard-duke-large">
          <div class="brand text-center mb-3">
            <h3><small>VERIFICATION</small><br />DECLINED</h3>
          </div>
          <div>
            <i class="fal fa-times fa-3x"></i>
          </div>
          <div class="pl-5 pr-5 mt-3 text-center">
            <p>
              <small>Thank you for creating your
                <strong>DUKE Club Press</strong> account.<br />
                Unfortunately your Press Account request has been
                <strong>declined</strong>. Please contact the DUKE team per
                email to get additional information.</small>
            </p>
            <p class="mt-3">
              <small><strong>ID: {{ userId.substring(0, 8) }}</strong></small>
            </p>
            <p>
              <small><strong>ACCOUNT: {{ userDoc.email }}</strong></small>
            </p>
            <p>
              <small><strong>
                  REQUESTED ON:
                  {{
                    $moment($C.getDateFromTimestamp(userDoc.createdOn)).format(
                      "DD-MM-YYYY"
                    )
                  }}</strong></small>
            </p>
            <p>
              <small><strong>
                  DECLINED ON:
                  {{
                    $moment($C.getDateFromTimestamp(userDoc.declinedOn)).format(
                      "DD-MM-YYYY"
                    )
                  }}</strong></small>
            </p>
          </div>
        </div>
      </section>

      <!--------------------- section verified --------------------->
      <section v-if="!isLoading && !showError && isVerifiedPress" key="content"
        class="section-duke section-dark jpadding jpadding-25">
        <div class="jtitle jtitle-80 text-center">
          <h3>PRESS CLUB</h3>
          <p class="mt-3">
            <small>Welcome to the DUKE Club. As a press member you have access to
              exclusive content.</small>
          </p>
        </div>

        <div class="grid-2">
          <router-link :to="{ name: 'DashboardPress' }" class="jcard-duke">
            <i class="fal fa-file-pdf fa-3x mb-3"></i>
            <h5>Press Kit</h5>
            <p class="m-0"><small>PRESS CLUB</small></p>
          </router-link>
          <div class="jcard-duke">
            <i class="fal fa-newspaper fa-3x mb-3"></i>
            <h5>News</h5>
            <p class="m-0"><small>PRESS CLUB</small></p>
          </div>
          <div class="jcard-duke">
            <i class="fal fa-tint fa-3x mb-3"></i>
            <h5>Brand Guide</h5>
            <p class="m-0"><small>PRESS CLUB</small></p>
          </div>
          <div class="jcard-duke">
            <i class="fal fa-paint-brush fa-3x mb-3"></i>
            <h5>Design Assets</h5>
            <p class="m-0"><small>PRESS CLUB</small></p>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
// Component imports
import EmailVerificationRequired from '@/components/user/EmailVerificationRequired.vue';
import Loading from "../general/Loading.vue";
// Logic imports
import C from "@/const";
import firebase from "@/firebase/firebaseInit";
const db = firebase.db;
const auth = firebase.auth;

export default {
  name: "DashboardPress",
  components: {
    Loading,
    EmailVerificationRequired,
  },
  data() {
    return {
      title: "PRESS CLUB",
      isLoading: true,
      isEmpty: false,
      isLoggedIn: false,
      isPress: false,
      isVerifiedPress: false,
      user: null,
      userId: "",
      emailVerified: auth.currentUser.emailVerified,
    };
  },
  methods: {
    loadingDone(error) {
      this.$root.$emit('loadingDone', error);
      if (!error)
        this.isLoading = false;
    },
    // this can be called by the EmailVerification component to check for verification changes and hide the component if required
    reloadUserToCheckVerification() {
      auth.currentUser.reload().then(() => {
        this.emailVerified = auth.currentUser.emailVerified;
        console.log("verification refresh: ");
        console.log(auth.currentUser.emailVerified);
      });
    },
    logout() {
      auth.signOut().then(() => {
        this.$router.replace({ name: "Login" });
      });
    },
  },
  computed: {
    userDoc: {
      get() {
        return this.$store.getters.getUser;
      },
    },
    userName: {
      get() {
        return (this.user && this.user.firstName && this.user.lastName) ? (this.user.firstName + ' ' + this.user.lastName) : "";
      }
    }
  },
  created() {
    //init
    this.isLoading = true;
    this.user = auth.currentUser;
    if (!this.user) {
      this.loadingDone('Not logged in')
      this.showError = true;
      return;
    }
    this.userId = this.user.uid;
    //check if verified
    this.user.getIdTokenResult().then((idTokenResult) => {
      if (idTokenResult.claims.press) {
        this.isPress = true;
      }
      if (idTokenResult.claims.status == C.STATUS.STATUS.ACTIVE) {
        this.isVerifiedPress = true;
      }
    });
    //load user
    var userDocRef = db.collection("users").doc(this.userId);
    userDocRef
      .get()
      .then((userDoc) => {
        this.$store.commit("setUser", userDoc.data());
        this.loadingDone();
      })
      .catch(function (error) {
        console.log("Error getting USER document:", error);
        this.loadingDone(error);
      });
  },
};
</script>

<style lang="scss" scoped></style>
